import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ siteTitle }) => (
  <header>
    <div class="s-container">
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <img src="/images/allison-logo.svg" alt={siteTitle} />
        </Link>
      </h1>
      <p className="allison-role">
        Mixed Methods
        <br />
        Researcher
      </p>
      <a
        href="https://uxfol.io/allison-robins-mini"
        className="button"
        id="cta-header"
        target="_blank"
      >
        See my work
      </a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
