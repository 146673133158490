import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'
import '../styles/global.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="main">{children}</div>
        <div className="footer__spacer">&nbsp;</div>
        <footer>
          <div className="s-container s-container--center">
            <div className="grid">
              <div className="lets-chat">
                <h2 class="h1">Let's chat</h2>
                <p className="subhead">
                  If you're looking for an ambidextrous thinker that is as
                  comfortable with getting to know your users as she is with
                  scaling research practices, we should talk.
                </p>
                <a
                  href="https://calendly.com/allisonrobins"
                  className="button"
                  id="meeting-footer"
                  target="_blank"
                >
                  Book a meeting
                </a>
              </div>
            </div>
          </div>
          <div className="s-social-icons">
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/in/robinsallison/"
                  target="_blank"
                  id="linkedin"
                >
                  <img src="/images/linkedin.svg" class="" />
                </a>
              </li>
              <li>
                <a
                  href="mailto:robins.alli@gmail.com"
                  target="_blank"
                  id="email"
                >
                  <img src="/images/mail.svg" class="" />
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
